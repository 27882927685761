import React from "react";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DetalleCarrito from "./DetalleCarrito";
import Formulario from "./Formulario";
import Mensaje from "./MensajeEnviado";
import { vaciarCarritoActions } from "../../state/reducers/carritoReducers";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonMovil: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize:"10px"
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

function getSteps() {
  return ["Detalle Carrito", "Encabezado Carrito", "Finalizar"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <DetalleCarrito />;
    case 1:
      return <Formulario />;
    case 2:
      return <Mensaje />;
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const theme = useTheme()
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const StepperDe = useSelector((state) => state.enviarDatos);
  const { carrito } = useSelector((state) => state.carrito);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <main className={isMobile?"":classes.layout}>
      <Stepper
        activeStep={StepperDe.enviado ? 2 : activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>
                {getStepContent(StepperDe.enviado ? 2 : index)}
              </Typography>
              <div className={classes.actionsContainer}>
                {carrito.length>0?
                <div>
                  {StepperDe.enviado ? null : activeStep === 1 ? null : (
                    <Button
                      color="secondary"
                      className={isMobile?classes.buttonMovil:classes.button}
                      onClick={() => dispatch(vaciarCarritoActions())}
                    >
                      Vaciar Carrito
                    </Button>
                  )}
                  {StepperDe.enviado ? null :activeStep === 0 ? null : (
                    <Button
                      onClick={handleBack}
                      className={isMobile?classes.buttonMovil:classes.button}
                      color="secondary"
                    >
                      Regresar
                    </Button>
                  )}
                  {StepperDe.enviado ? null : activeStep === 1 ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={isMobile?classes.buttonMovil:classes.button}
                    >
                      Confirmar datos de la cotizacion
                    </Button>
                  )}
                </div>
               :null }
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </main>
  );
}
