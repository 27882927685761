import React from "react";
import { Typography, Box } from "@material-ui/core";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { makeStyles ,useTheme} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import {
  eliminarCarritoActions,
  cambioEmpaqueActions,
  cambioCantidadActions,
} from "../../state/reducers/carritoReducers";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import MaterialTable from "material-table";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  thumb: {
    borderRadius: "100%",
    width: 100,
    height: 100,
    overflow: "hidden",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    "& img": {
      maxWidth: "100%", 
    },
  },
  thumbMovil: {
    borderRadius: "100%",
    width: 50,
    height: 50,
    overflow: "hidden",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    "& img": {
      maxWidth: "100%",
    },
  },

  quantity: {
    width: 60,
    marginRight: 30,
    marginLeft: 10,
    "& input": {
      textAlign: "right",
    },
  },
  quantityMovil: {
    fontSize: "10px",
    width: 40,
    marginRight: 10,
    marginLeft: 10,
    "& input": {
      textAlign: "center",
    },
  },
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));
const DetalleCarrito = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const { carrito } = useSelector((state) => state.carrito);
  const cambiarEmpaque = (item, carrito, Empaque) => {
    item.Base = Empaque;

    dispatch(cambioEmpaqueActions(item, carrito));
  };
  const cambioCantidad = (value) => {
    const UpdateCantidadItemA = (e) => {
      if (e.target.value > 0) {
        value.Cantidad = e.target.value;
        dispatch(cambioCantidadActions(value, carrito));
      }
    };

    return (
      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ min: 1 }}
        margin="none"
        value={value.Cantidad}
        className={!isMobile ? classes.quantity : classes.quantityMovil}
        onChange={UpdateCantidadItemA}
      />
    );
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      {!isMobile ? (
        <MaterialTable
          title={
            <>
              <Typography variant="h6" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ShoppingCart color="primary" />
                Resumen carrito
              </Typography>
            </>
          }
          columns={[
            {
              title: "",
              render: (rowData) => {
                return (
                  <>
                    <Link to={`producto/${rowData.ItemCodeAux}/`}>
                      <figure className={classes.thumb}>
                        <img
                          src={`https://disdelsa.com/imagenes/productos/${rowData.Imagen}`}
                          alt="thumb"
                        />
                      </figure>
                    </Link>
                  </>
                );
              },
            },
            {
              title: "Descripcion",
              field: "Descripcion",
              headerStyle: {
                minWidth: 350,
              },
            },
            {
              title: "Cantidad",
              render: (rowData) => {
                return cambioCantidad(rowData);
              },
            },
            {
              title: "Empaque",
              render: (rowData) => {
                return rowData.Base === "Y" ? rowData.Unidad : rowData.Fardo;
              },
            },
            {
              render: (rowData) => {
                return (
                  rowData.BaseFardo !== rowData.BaseUnidad && (
                    <Tooltip title="Cambiar empaque">
                      <AutorenewIcon
                        color="primary"
                        onClick={() =>
                          cambiarEmpaque(
                            rowData,
                            carrito,
                            rowData.Base === "Y" ? "N" : "Y"
                          )
                        }
                      />
                    </Tooltip>
                  )
                );
              },
            },
            {
              render: (rowData) => {
                return (
                  <Tooltip title="Eliminar">
                    <ClearIcon
                      color="secondary"
                      onClick={() =>
                        dispatch(
                          eliminarCarritoActions(
                            rowData.Descripcion,
                            rowData.CodProducto,
                            carrito
                          )
                        )
                      }
                    />
                  </Tooltip>
                );
              },
            },
          ]}
          onRowClick={(togglePanel) => togglePanel}
          data={carrito}
          options={{
            actionsColumnIndex: -1,
            tableLayout: "auto",
            headerStyle: {
              fontSize: "13.5px",
            },
            cellStyle: {
              fontSize: "12px",
            },
          }}
          localization={{
            header: {
              actions: "Accion",
            },
          }}
        />
      ) : (
        <List className={classes.root}>
          <Typography variant="subtitle2">
            <ShoppingCart color="primary" />
            Resumen carrito
          </Typography>
          <Divider />
          {carrito.map((product) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Link to={`producto/${product.ItemCodeAux}/`}>
                    <Avatar
                      alt="Remy Sharp"
                      src={`https://disdelsa.com/imagenes/productos/${product.Imagen}`}
                    />
                  </Link>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography style={{ fontSize: "11px" }}>
                      <Box fontWeight="fontWeightBold">
                        {product.Descripcion}
                      </Box>
                    </Typography>
                  }
                  secondary={
                    <Typography style={{ fontSize: "9px" }}>
                      {cambioCantidad(product)}
                      {product.Base === "Y" ? product.Unidad : product.Fardo}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="comments">
                    {product.BaseFardo !== product.BaseUnidad && (
                      <AutorenewIcon
                        color="primary"
                        onClick={() =>
                          cambiarEmpaque(
                            product,
                            carrito,
                            product.Base === "Y" ? "N" : "Y"
                          )
                        }
                      />
                    )}
                  </IconButton>
                  <IconButton edge="end" aria-label="comments">
                    <ClearIcon
                      color="secondary"
                      onClick={() =>
                        dispatch(
                          eliminarCarritoActions(
                            product.Descripcion,
                            product.CodProducto,
                            carrito
                          )
                        )
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
        </List>
      )}
    </>
  );
};

export default DetalleCarrito;
