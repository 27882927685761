import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";
import { ColocarEnfalsoEnviado } from "../../state/reducers/enviarDatosReducers";
import { useDispatch,useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  finishMessage: {
    textAlign: "center",
    maxWidth: 600,
    margin: "0 auto",
    "& h4": {
      "& span": {
        textAlign: "center",
        display: "block",
        "& svg": {
          color: theme.palette.primary.main,
          height: "auto",
          width: 148,
        },
      },
    },
  },
  button: {
    display: "inline-block",
    textDecoration: "none",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Mensaje = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enviarDatos = useSelector((state) => state.enviarDatos);
  return (
    <div className={classes.finishMessage}>
      <Typography variant="h4" gutterBottom>
        <span>
          <CheckCircle />
        </span>
        Gracias por cotizar
      </Typography>
      <Typography variant="subtitle1">
        &nbsp;
        <strong>{enviarDatos.enviado ? enviarDatos.formulario.Mensaje:""}</strong>
        .&nbsp;
      </Typography>
      <Link to={`/`}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>dispatch(ColocarEnfalsoEnviado())}
        >
          Cotizar de nuevo
        </Button>
      </Link>
    </div>
  );
};

export default Mensaje;
