import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import {
  enviarDataActions,
  LevantarDialogActions,
} from "../../state/reducers/enviarDatosReducers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    position: "fixed",
    width: "100%",
    top: "0",
    left: "0",
    right: "0",
    padding: "20px 0",
    transition: "padding 0.3s linear",
    zIndex: "999999",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonMovil: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: "10px"
  },
  recaptchaContainer:{
    border:'1px solid red'
  }
}));

const AddressForm = () => {
  const classes = useStyles();
  const { desktop } = useSelector((state) => state.layout);
  const correoValido = RegExp(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  );
  const dispatch = useDispatch();
  const { carrito } = useSelector((state) => state.carrito);

  const TEST_SITE_KEY = "6Le-gQwcAAAAAPSRxZ67QFQZippBROfdChYcUIoJ";
  const [stateGeneral, setState] = useState({
    callback: "not fired",
    value: "empty",
    load: false,
    expired: "false",
  });
  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" });
  };
  const handleChangeRecaptcha = (value) => {
    setState({ value });
    if (value === null || value == undefined || value === "") {
      setState({ expired: "true" });
    }
  }
  const [Datos, setDatos] = useState({
    nit: "",
    empresa: "",
    telefono: "",
    nombre: "",
    apellido: "",
    correo: "",
  });
  const { nit, empresa, telefono, nombre, apellido, correo } = Datos;
  const [boolTelefono, setTelefono] = useState(false);

  const onChangeFormulario = (e) => {
    setDatos({
      ...Datos,
      [e.target.name]: e.target.value,
    });
    if ((telefono, nombre, correo)) {

    }
  };
  const boolCaracteres = () => {
    ValidatorForm.addValidationRule("boolC", (value) => {
      const numeroValido = RegExp(
        /^[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}$/
      );
      setTelefono(numeroValido.test(value));
      if (numeroValido.test(value)) {
        return true;
      } else {
        return false;
      }
    });
  };
  boolCaracteres();

  const enviarDatos = useSelector((state) => state.enviarDatos);

  const enviarFormulario = (e) => {
    e.preventDefault();

    const Data = {
      Encabezado: {
        nombre,
        apellido,
        u_DoctoNIT: nit,
        correo,
        autor: `${nombre} ${apellido}`,
        nombreCliente: empresa,
        telefono,
        TipoCliente: "",
        comentario: `${correo} tel: ${telefono}`,
        Empresa: "Disdel 3M ",
        Recaptcha_key: stateGeneral.value,
        PaginaProvenienteRecaptcha: "reCaptchaPublicKeyDisdel3M"
      },
      Detalle: carrito,
    };
    dispatch(LevantarDialogActions(true));
    dispatch(enviarDataActions(Data));
  };
  return (
    <Fragment>
      <ValidatorForm onSubmit={enviarFormulario}>
        <Typography variant="subtitle2" color="secondary" >( * Requeridos)</Typography >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextValidator
              helperText="Empresa"
              name="empresa"
              value={empresa}
              onChange={onChangeFormulario}
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextValidator
              fullWidth
              helperText="Nit"
              name="nit"
              value={nit}
              onChange={onChangeFormulario}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              helperText="*Nombre"
              name="nombre"
              value={nombre}
              onChange={onChangeFormulario}
              validators={["required"]}
              errorMessages={["este campo es obligatorio"]}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              helperText="Apellido"
              name="apellido"
              value={apellido}
              onChange={onChangeFormulario}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              helperText="*Telefono"
              value={telefono}
              name="telefono"
              onChange={onChangeFormulario}
              validators={["boolC", "required"]}
              errorMessages={["Numero no valido", "este campo es obligatorio"]}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              helperText="*Correo"
              name="correo"
              value={correo}
              onChange={onChangeFormulario}
              validators={["required", "isEmail"]}
              errorMessages={[
                " este campo es obligatorio ",
                "el correo electrónico no es válido ",
              ]}
              fullWidth
              autoFocus
            />
          </Grid>
        </Grid>

        <br />

        {carrito.length > 0 ? (
          <>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {enviarDatos.buttonDialog ? (
                <Backdrop
                  className={classes.backdrop}
                  open={enviarDatos.buttonDialog}
                >
                  <CircularProgress color="inherit" />
                  Enviando Cotizacion
                </Backdrop>
              ) : (
                <>
                  <ReCAPTCHA
                      style={{ display: "inline-block",width:'100%' }}
                      theme="light"
                      sitekey={TEST_SITE_KEY}
                      onChange={handleChangeRecaptcha}
                      asyncScriptOnLoad={asyncScriptOnLoad}
                  />
                  <br />
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!nombre || !boolTelefono || !correoValido.test(correo) || !stateGeneral.value}
                    className={desktop ? classes.button : classes.buttonMovil}
                  >
                    Finalizar Cotización
                  </Button>
                </>
              )}
            </Grid>
          </>
        ) : null}
      </ValidatorForm>
    </Fragment>
  );
};

export default AddressForm;
